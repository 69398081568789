import { FC, ReactNode } from 'react';

import { ScrollLabel } from '@vakantiesnl/components/src/__LEGACY__/atoms/ScrollLabel';

import styles from './ImageGrid.module.css';

type Props = {
	children?: ReactNode;
};

export const ImageGrid: FC<Props> = ({ children }) => (
	<div className={styles.gridContainer}>
		<ScrollLabel title="Scroll" />
		<div className={styles.grid}>{children}</div>
	</div>
);
