import { FC, useCallback } from 'react';

import { SubMenuItem as SubMenuItemComponent } from '@vakantiesnl/components/src/__LEGACY__/atoms/SubMenuItem/SubMenuItem';
import { ContentfulLink, MicroCopy } from '@vakantiesnl/types';

import styles from './SubMenu.module.css';

type Props = {
	menuItems: ContentfulLink[];
	isToggled?: boolean;
	microCopies: MicroCopy;
	closeMenu?: VoidFunction;
};

type SubMenuItemProps = {
	title: string;
	link: string;
	target: '_self' | '_blank';
	closeMenu?: VoidFunction;
	onClick?: VoidFunction;
	toggled?: boolean;
	isExternal: boolean;
};

const SubMenuItem: FC<SubMenuItemProps> = (props: SubMenuItemProps) => {
	const onClickMenuItem = useCallback((): void => {
		props.closeMenu && props.closeMenu();
		import('@vakantiesnl/services/src/gtm/common').then(({ track, clickEvent }) => {
			track(clickEvent(`${document.title}/header`, props.title, props.link));
		});
	}, [props]);

	return <SubMenuItemComponent {...props} onClick={onClickMenuItem} />;
};

export const SubMenu: FC<Props> = ({ menuItems, isToggled, microCopies, closeMenu }) => (
	<div className={styles.subMenuContainer} data-toggled={isToggled ? 'toggled' : 'unToggled'}>
		<span className={styles.subMenuMobileHeader}>{microCopies['serviceAndContact']}</span>
		<nav className={styles.subMenu}>
			<ul className={styles.subMenuList}>
				{menuItems.map(({ title, link, target, isExternal }, key) => (
					<SubMenuItem
						link={link}
						title={title}
						key={`submenu-${key}`}
						target={target}
						toggled={isToggled}
						closeMenu={closeMenu}
						isExternal={isExternal}
					/>
				))}
			</ul>
		</nav>
	</div>
);
