import { FC } from 'react';

import { USP } from '@vakantiesnl/components/src/__LEGACY__/atoms/USP';
import { Usp } from '@vakantiesnl/types';

import css from './USPBlock.module.css';

type Props = {
	usps: Usp[];
};

export const USPBlock: FC<Props> = ({ usps }) => (
	<div className={css.uspBlock}>
		{usps.map(({ title, subtitle }, key) => (
			<USP variant="footer" title={title} subtitle={subtitle} key={key} />
		))}
	</div>
);
