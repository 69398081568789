import { deprecatedMakeStyles } from '@vakantiesnl/components/src/styles/themes/themewrapper';

const useStyles = deprecatedMakeStyles()((theme) => ({
	imageContainer: {
		width: 80,
		height: 80,
		position: 'relative',
		padding: theme.spacing(1),
		outline: theme.borders?.darkgray,
		borderRadius: theme.spacing(0.5),
		cursor: 'pointer',
		'& img': {
			width: '100%',
			height: '100%',
		},
		[theme.breakpoints.up('xl')]: {
			width: 117,
			height: 117,
		},
	},
	alert: {
		width: 'auto',
		maxWidth: '100%',
		position: 'fixed',
		top: 0,
		right: 0,
		[theme.breakpoints.up('xl')]: {
			maxWidth: '50%',
		},
	},
	tabletIcon: {
		width: 15,
		height: 15,
		position: 'absolute',
		bottom: 3,
		right: 2,
	},
	desktopIcon: {
		width: 0,
		height: 0,
		position: 'absolute',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
	},
	modalContainer: {
		width: '100%',
		height: '100%',
		position: 'fixed',
		top: 0,
		left: 0,
		zIndex: 1,
		background: `rgba(0, 0, 0, 0.5)`,
	},
	modalImage: {
		width: 'auto',
		maxWidth: '1000px',
		height: '100%',
		objectFit: 'contain',
		position: 'absolute',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		background: theme.palette.common.white,
	},
	modalIcon: {
		padding: 0,
		border: 'none',
		width: theme.spacing(4),
		height: theme.spacing(4),
		position: 'absolute',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		cursor: 'pointer',
		color: theme.palette.grey[300],
		background: 'transparent',
		borderRadius: theme.shape.borderRadius - 3,
		transition: 'background .1s ease-out',
		'&:hover': {
			background: theme.palette.grey[800],
		},
	},
	modalCloseIcon: {
		top: 20,
		right: 20,
	},
	modalDownloadImageIcon: {
		top: 20,
		right: 60,
		'& svg': {
			width: theme.spacing(3.5),
		},
	},
}));
export default useStyles;
