import { FC, useCallback, useState, useEffect, MouseEvent } from 'react';

import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { Icon } from '@vakantiesnl/components/src/__LEGACY__/atoms/Icon/index';
import { AlertBlock } from '@vakantiesnl/components/src/__LEGACY__/molecules/AlertBlock/index';
import { useMicroCopyContext } from '@vakantiesnl/services/src/context/microCopyContext';

import useStyles from './DownloadImage.style';

type Props = {
	nameIcon: string;
	pathIcon: string;
};

export const DownloadImage: FC<Props> = ({ nameIcon, pathIcon }) => {
	const { classes: style, cx } = useStyles();
	const theme = useTheme();
	const microCopies = useMicroCopyContext();
	const [showAlert, setShowAlert] = useState<boolean>(false);
	const [modal, setModal] = useState<boolean>(false);
	const isDesktopOrTablet = useMediaQuery(theme.breakpoints.up('lg'));

	const modalOpen = useCallback((): void => setModal(true), []);
	const modalClose = useCallback((): void => setModal(false), []);

	useEffect(() => {
		const close = (e: KeyboardEvent): void => {
			if (e.key === 'Escape') {
				setModal(false);
			}
		};
		window.addEventListener('keydown', close);
		return () => window.removeEventListener('keydown', close);
	}, []);

	const propagationStop = useCallback((e: MouseEvent) => {
		e.stopPropagation();
	}, []);

	const downloadHandler = useCallback(
		(e: MouseEvent) => {
			fetch(pathIcon)
				.then((response) => response.blob())
				.then((blob) => {
					const link = document.createElement('a');
					link.href = URL.createObjectURL(blob);
					link.download = nameIcon;
					document.body.appendChild(link);
					link.click();
					document.body.removeChild(link);
				})
				.catch(() => {
					setShowAlert(true);
				});
			e.stopPropagation();
		},
		[setShowAlert, pathIcon, nameIcon],
	);

	return (
		<>
			<div className={style.imageContainer} onClick={isDesktopOrTablet ? modalOpen : downloadHandler}>
				{showAlert && (
					<AlertBlock className={style.alert} status="warning">
						{microCopies['notification.uploadError']}
					</AlertBlock>
				)}
				<img src={pathIcon} alt={nameIcon} />
				{!isDesktopOrTablet && <Icon className={style.tabletIcon} type="downloadPress" />}
			</div>
			{modal && (
				<div className={style.modalContainer} onClick={modalClose}>
					<img className={style.modalImage} src={pathIcon} alt={nameIcon} onClick={propagationStop} />
					<button className={cx(style.modalIcon, style.modalDownloadImageIcon)} onClick={downloadHandler}>
						<Icon type="downloadImage" />
					</button>
					<button className={cx(style.modalIcon, style.modalCloseIcon)}>
						<Icon type="cross" />
					</button>
				</div>
			)}
		</>
	);
};
