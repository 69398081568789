import { deprecatedMakeStyles } from '@vakantiesnl/components/src/styles/themes/themewrapper';

const useStyles = deprecatedMakeStyles()(() => ({
	menuHeader: {
		display: 'flex',
		justifyContent: 'space-between',
	},
}));

export default useStyles;
