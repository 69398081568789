import { FC, useCallback } from 'react';

import ImageComponent from '@vakantiesnl/image-component';
import { ContentfulImageParams } from '@vakantiesnl/image-component/src/loaders/contentful';
import { ImageLink } from '@vakantiesnl/types';

import css from './ContactBlock.module.css';

type Props = {
	contactLinks: ImageLink[];
};

type LinkProps = {
	item: ImageLink;
};

const contentfulParams: ContentfulImageParams = { fm: 'webp' };

const ContactLink: FC<LinkProps> = ({ item }) => {
	const onClick = useCallback((): void => {
		import('@vakantiesnl/services/src/gtm/common').then(({ track, clickEvent }) =>
			track(clickEvent(`${document.title}/footer`, item.title, item.link)),
		);
	}, [item]);

	return (
		<li className={css.contactItem}>
			<a href={item.link} onClick={onClick} title={item.title}>
				<div>
					<ImageComponent
						useLoader="Contentful"
						contentfulParams={contentfulParams}
						fill={true}
						className={css.contactImage}
						sizes="20px"
						src={item.image}
						alt={item.title}
					/>
				</div>
				<span>{item.title}</span>
			</a>
		</li>
	);
};

export const ContactBlock: FC<Props> = ({ contactLinks }) => (
	<div className={css.contactBlock}>
		<ul className={css.contactItems}>
			{contactLinks.map((item, key) => (
				<ContactLink item={item} key={key} />
			))}
		</ul>
	</div>
);
