import { FC, PropsWithChildren } from 'react';

import { Icon } from '@vakantiesnl/components/src/__LEGACY__/atoms/Icon';
import cls from 'classnames';

import { exclamationIcon, errorIcon } from './assets/svg';
import styles from './styles.module.css';

type BlockProps = {
	status: 'info' | 'warning';
	blockStyles?: string;
	className?: string;
	['data-cy']?: string;
};

export const AlertBlock: FC<PropsWithChildren<BlockProps>> = ({
	children,
	status,
	blockStyles,
	className,
	...rest
}) => (
	<div
		className={cls(status === 'warning' ? styles.errorBlock : styles.infoBlock, blockStyles, className)}
		data-cy={rest['data-cy']}
	>
		<Icon
			className={status === 'warning' ? styles.warningIcon : styles.infoIcon}
			symbol={status === 'warning' ? errorIcon : exclamationIcon}
		/>
		<span className={styles.errorMessage}>{children}</span>
	</div>
);
